import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import Category from '@pages/category';
import Error from '@pages/error';

import { withApollo } from '../graphql/apollo';
import { withTranslation, i18n } from '../i18n';

import categoryQuery from '../graphql/queries/category.graphql';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE }
} = getConfig();

const CategoryPage = ({ data, site }) =>
  data && data.entry ? (
    <Category site={site} data={data} />
  ) : (
    <Error statusCode={404} site={site} />
  );

CategoryPage.getInitialProps = async ctx => {
  const {
    req,
    query: { slug, group },
    apolloClient
  } = ctx;

  const { language } = req || i18n;
  const site = `${ACTIVE_SITE_HANDLE}${language.toUpperCase()}`;

  const { data, error } = await apolloClient.query({
    query: categoryQuery,
    variables: { site, slug, group }
  });

  if ((data && !data.entry) || error) {
    if (ctx.res) {
      ctx.res.statusCode = 404;
    }
  }

  return {
    namespacesRequired: ['common'],
    site,
    data
  };
};

CategoryPage.propTypes = {
  site: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired
};

CategoryPage.defaultProps = {};

export default withApollo(withTranslation('common')(CategoryPage));
