import React from 'react';
import PropTypes from 'prop-types';

import { Stage, TeaserContainer, TeaserOverview } from '@ccg/fischerappelt';

import AutomaticTeaserContainer from '../../shared/TeaserContainer/AutomaticTeaserContainer';

import PageTemplate from '../templates/page';
import { removeSpecialCharacters } from '../../../helper';

import Embeds from '../../shared/Embeds/Embeds';

import DynamicTeaser from '../../shared/TeaserContainer/subcomponents/DynamicTeaser';

import { withTranslation } from '../../../i18n';

const Category = ({ data, t }) => {
  const { entry, footer, navigation, seoDefaults, cookieLayer, social } = data;

  // const { relatedCasesCount } = data.teaserSettings;
  // const limit = relatedCasesCount ? relatedCasesCount : 4;
  // TODO: globals setting? see above... or for each category group or for all?
  const limit = 3;

  return (
    <PageTemplate
      navigation={navigation}
      seo={entry}
      footer={footer}
      social={social}
      seoDefaults={seoDefaults}
      cookieLayer={cookieLayer}
      hasAccentLine={entry.hasAccentLine}
    >
      <Stage
        size={entry.contentType === 'advertising' ? 'large' : 'small'}
        image={
          entry.image &&
          entry.image.length > 0 && {
            ...entry.image[0],
            srcset: {
              '1440w': entry.image[0].width1440,
              '960w': entry.image[0].width960,
              '720w': entry.image[0].width720,
              '375w': entry.image[0].width375
            }
          }
        }
        video={entry.video && entry.video.length > 0 && entry.video[0]}
        loop={entry.loop}
        title={removeSpecialCharacters(entry.title)}
        headline={entry.headline || entry.title}
        subline={entry.subline}
        excerpt={entry.excerpt}
      />

      <Embeds embedsCopy={entry.embedsCopy} embeds={entry.embeds} />

      {entry.groupHandle === 'locations' && (
        <>
          <AutomaticTeaserContainer
            headline={t('filter.companies')}
            type="categories"
            sections={['companies']}
            limit={limit}
            showLoadMore
            additionalQueryArgs={{ locations: [entry.id] }}
          />

          <AutomaticTeaserContainer
            headline={t('filter.locations')}
            type="categories"
            sections={['locations']}
            limit={limit}
            showLoadMore
          />
        </>
      )}

      {entry.groupHandle === 'companies' && (
        <>
          <AutomaticTeaserContainer
            headline={t('teaser.cases')}
            type="entries"
            sections={['cases']}
            limit={limit}
            showLoadMore
            additionalQueryArgs={{ companies: [entry.id] }}
          />

          {!entry.hideLocationsTeaser && entry.locations && entry.locations.length > 0 && (
            <>
              <TeaserOverview headline={t('filter.locations')} />
              <TeaserContainer>
                {entry.locations.map((location, index) => (
                  <DynamicTeaser index={index + 1} key={location.id} {...location} />
                ))}
              </TeaserContainer>
            </>
          )}
        </>
      )}

      {entry.groupHandle === 'locations' && (
        <>
          <AutomaticTeaserContainer
            headline={t('teaser.currentNews')}
            type="entries"
            sections={['news']}
            limit={limit}
            showLoadMore
            additionalQueryArgs={{ locations: [entry.id] }}
          />
        </>
      )}

      {(entry.groupHandle === 'solutions' ||
        entry.groupHandle === 'industries' ||
        entry.groupHandle === 'tasks' ||
        entry.groupHandle === 'clients') && (
        <AutomaticTeaserContainer
          headline={t('teaser.cases')}
          type="entries"
          sections={['cases']}
          limit={limit}
          showLoadMore
          additionalQueryArgs={{ [entry.groupHandle]: [entry.id] }}
        />
      )}
    </PageTemplate>
  );
};

Category.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired
};

Category.defaultProps = {};

export default withTranslation('common')(Category);
